<template>
  <div id="footer">
    <li class="about">
      <h2>友情链接：</h2>

        <p><a href="http://www.gcc.edu.cn" target="_blank">&nbsp;&nbsp;&nbsp;&nbsp;<strong>广州商学院</strong></a></p>


    </li>
    <!-- 分割线 -->
    <li class="split"></li>
    <li class="about">
      <h2>关于本站：</h2>
      <p><strong>版权所有：</strong>© 广州商学院&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p><strong>地址：</strong>广东省广州市黄埔区&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <p><strong>邮编：</strong>5230000</p>
      <p><a href="https://beian.miit.gov.cn/">&nbsp;&nbsp;&nbsp;&nbsp;<strong>粤ICP备2024250542号-1</strong></a></p>
    </li>
  </div>
</template>

<script>
  export default {
    name: '',
    data() {
      return {}
    },
    methods: {},
  }
</script>

<style scoped lang="less">
#footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 5px 15px;
  box-shadow: 0 -0.1px 3px #dadada;
}

li {
  display: flex;
  width: 50%; /* 将li的宽度设为50%，使其水平排列 */
  color: #000;
  font-size: 3.5px;
}

h2, .link > span {
  display: flex;
  align-items: center;
  height: 100%; /* 设置"H2"和".link > span"的高度为100%，保持垂直居中对齐 */
}

.link > span {
  width: 70%; /* 调整.span的宽度，使其与"H2"标签水平对齐 */
  padding: 2px 0;
  font-size: 5px;
}

.split {
  width: 0.2px;
  background-color: #bdbdbd;
}

.link {
  display: flex;
  flex-wrap: wrap;

}

p {
  padding-top: 1px;
}
.about p {
  font-size: 3px; /* 设置<p>标签内文字的字体大小为16px，可以根据需要进行调整 */
}
.about strong {
  font-size: 3px; /* 设置<p>标签内文字的字体大小为16px，可以根据需要进行调整 */
}
h2, .link {
  display: flex;
  align-items: center; /* 垂直居中 */
}


</style>
